@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(162, 79, 0, 0.927),
    rgba(189, 93, 2, 0.908)
  ) !important;
}

/* #home {
  width: 100%;
  height: 100vh;
} */

/* #video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
} */

/* .bg-overlay {
    height: 100%;
    width: 100%;
    background-color: black;
    top: 0;

} */

#navbar {
  z-index: 1;
}

#writing {
  position: absolute;
  top: 0;
}

#form {
  position: absolute;
  top: 0;
}

/* @media screen and (min-width: 480px) {
    #form {
        flex: auto;
        justify-content: center;
    }
} */
